import request from '@/utils/request'


// 查询用户-用户个人认证列表
export function listAuthPersonal(query) {
  return request({
    url: '/user/auth-personal/list',
    method: 'get',
    params: query
  })
}

// 查询用户-用户个人认证分页
export function pageAuthPersonal(query) {
  return request({
    url: '/user/auth-personal/page',
    method: 'get',
    params: query
  })
}

// 查询用户-用户个人认证详细
export function getAuthPersonal(data) {
  return request({
    url: '/user/auth-personal/detail',
    method: 'get',
    params: data
  })
}

// 新增用户-用户个人认证
export function addAuthPersonal(data) {
  return request({
    url: '/user/auth-personal/add',
    method: 'post',
    data: data
  })
}

// 修改用户-用户个人认证
export function updateAuthPersonal(data) {
  return request({
    url: '/user/auth-personal/edit',
    method: 'post',
    data: data
  })
}

// 删除用户-用户个人认证
export function delAuthPersonal(data) {
  return request({
    url: '/user/auth-personal/delete',
    method: 'post',
    data: data
  })
}
